import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, Link }  from 'gatsby'
import ScrollAnim from 'rc-scroll-anim'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div/Div.styles'
import H4 from '../components/H4'
import H5 from '../components/H5'
import H6 from '../components/H6'
import Paragraph from '../components/Paragraph'

import { Container } from '../components/styles/Layout.styles'

import { 
  styledCloudServer,
  header,
  scrollTo
} from '../components/styles/Contrato.styles'

export const PageTemplate = ({
  data,
  ...rest
}) => {
  return (
    <Fragment>
      <section css={styledCloudServer}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
          >
            <Column
              width={[1]}
            >
              <H4>Política de Segurança e Privacidade da Informação para Computação em Nuvem</H4>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
            pt={40}
            pb={40}
          >
            <Column
              mb={[30, 30, 0, 0]}
            >
              <Div
                css={scrollTo}
              >
                <H5>Política de Segurança da Informação para Computação em Nuvem</H5>
                <ScrollAnim.Link
                  to='proposito'
                  offsetTop='120'
                >
                  Propósito
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='seguranca'
                  offsetTop='120'
                >
                  Segurança da Informação<br /> para Nuvem
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='controles'
                  offsetTop='120'
                >
                  Controles de Segurança<br /> da Informação
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='riscos'
                  offsetTop='120'
                >
                  Riscos
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='incidentes'
                  offsetTop='120'
                >
                  Incidentes
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='gestao-de-mudancas'
                  offsetTop='120'
                >
                  Gestão de Mudanças
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='educacao-e-conscientizacao'
                  offsetTop='120'
                >
                  Educação e Conscientização
                </ScrollAnim.Link>
                <Link
                  to='/contrato'
                >
                  Voltar para Contrato
                </Link>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <Div id='proposito'>
                  <H6>1. Propósito</H6>
                  <Paragraph>A HostDime Brasil desenvolveu esta política como um anexo à <Link to='politica-de-seguranca-e-privacidade-da-informacao-e-servicos'>Política de Segurança e Privacidade da Informação e Serviços</Link>, com o propósito de enfatizar os objetivos específicos ligados ao serviço de nuvem. </Paragraph>
                  <ul>
                    <li>&#9679; Desenvolver os requisitos básicos sobre segurança da informação e privacidade voltadas ao serviço de cloud computing;</li>
                    <li>&#9679; Abordar riscos e oportunidades ligadas ao ambiente proposto;</li>
                    <li>&#9679; Descrever os procedimentos de controles de segurança da informação adotados; </li>
                    <li>&#9679; Estabelecer métodos e canais de comunicação;</li>
                    <li>&#9679; Definir papéis e responsabilidades; </li>
                    <li>&#9679; Documentação das fases do ciclo de vida das contas do cliente;</li>
                  </ul>
                  <Paragraph><span>1.1 Escopo</span></Paragraph>
                  <Paragraph>Esta política é voltada exclusivamente ao ambiente de Cloud Server da HostDime Brasil localizado no DC Nordeste - Brasil, através do modelo de implantação de nuvem publica utilizando o modelo de infraestrutura como um serviço.</Paragraph>
                </Div>
                <Div id='seguranca'>
                  <H6 style={{ marginTop: 24 }}>2. Segurança da Informação para Nuvem</H6>
                  <Paragraph>A HostDime Brasil tem o compromisso de proteger a <span>Confidencialidade, Integridade, Disponibilidade e Privacidade</span> do ambiente de cloud computing. Desta forma, todos os funcionários e contratados que operam direta ou indiretamente no serviço de nuvem em nome da organização, devem promover um ambiente em conformidade com os requisitos de segurança da informação estabelecidos nesta política.</Paragraph>
                </Div>
                <Div id='controles'>
                  <H6 style={{ marginTop: 24 }}>3. Controles de Segurança da Informação</H6>
                  <Paragraph>A segurança do ambiente de Cloud é dividida em quatro pilares:</Paragraph>
                  <ul>
                    <li>&#9679; <span>Segurança física dos hardwares:</span> responsável por garantir as operações da nuvem, suas interconexões e segregações físicas;</li>
                    <li>&#9679; <span>Segurança lógica na virtualização:</span> responsável por garantir a segregação dos ambientes multilocatários e dos dados de controle da solução</li>
                    <li>&#9679; <span>Segurança dos acessos remotos:</span> responsável por garantir o acesso seguro para administração e orquestração dos recursos da nuvem;</li>
                    <li>&#9679; <span>Segurança nos backups:</span> responsável por gerar snapshots das instâncias virtuais, disponibilizada de forma adicional aos clientes que desejam contratar a ferramenta.</li>
                  </ul>
                </Div>
                <Div id='riscos'>
                  <H6 style={{ marginTop: 24 }}>4. Riscos</H6>
                  <Paragraph>Os riscos relacionados a solução de nuvem, são mapeados e avaliados com base no Processo de Avaliação de Risco, sendo de responsabilidade da HostDime Brasil manter os riscos atualizados.</Paragraph>
                </Div>
                <Div id='incidentes'>
                  <H6 style={{ marginTop: 24 }}>5. Gestão de Incidentes</H6>
                  <Paragraph>Em caso de incidentes que afetem os pilares da segurança da informação na infraestrutura de Cloud Server, serão seguidas as diretrizes da Política de Gerenciamento de Incidentes de Segurança e Privacidade da Informação, realizando os seus devidos registros e tratamentos dos incidentes.</Paragraph>
                </Div>
                <Div id='gestao-de-mudancas'>
                  <H6 style={{ marginTop: 24 }}>6. Gestão de Mudanças</H6>
                  <Paragraph>Em caso de mudanças da infraestrutura de cloud, serão seguidas as diretrizes de Gerenciamento de Mudanças da organização.</Paragraph>
                </Div>
                <Div id='educacao-e-conscientizacao'>
                  <H6 style={{ marginTop: 24 }}>7. Educação e Conscientização</H6>
                  <Paragraph>O Plano de Comunicação da HostDime define as principais ações de educação e conscientização sobre diversos temas voltados a segurança da informação para o ambiente de nuvem. A <a href="https://ajuda.hostdime.com.br/display/SUP/CloudServer+HostDime">Central de Ajuda HostDime Cloud</a> dispõe das documentações de apoio ao cliente com todas as fases do ciclo de vida das contas dos clientes.</Paragraph>
                </Div>
              </Div>
            </Column>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object,
  values: PropTypes.object
}

const StaticPage = (props) => {
  return (
    <Layout>
      <Seo title='Política de Segurança da Informação para Computação em Nuvem' />
      <PageTemplate
        title='Política de Segurança da Informação para Computação em Nuvem '
        {...props}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "cloud-server/cloud-server.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default (StaticPage)
